import React, { useEffect, useLayoutEffect } from 'react';

class MyInterviewWidget extends React.Component {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://tp.media/content?trs=321308&shmarker=546553&locale=en&curr=PLN&powered_by=true&border_radius=0&plain=true&color_button=%232681ff&color_button_text=%23ffffff&color_border=%232681ff&promo_id=4132&campaign_id=121";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        script.onload = () => {
          var myInterviewRecorder = new MyInterviewWidget({
              container: '#mycustom-container', // querySelector to the element that will contain the widget
              config: {
                  apiKey: "TCeni10RVWO0jEJHN3uF"
              }
          });
        }
    }
    render() {
        return 
            <form>
                <div id="mycustom-container"> </div>
            </form>;
    }
}

export default MyInterviewWidget;


{/* <script async src="https://tp.media/content?trs=321308&shmarker=546553&locale=en&curr=PLN&powered_by=true&border_radius=0&plain=true&color_button=%232681ff&color_button_text=%23ffffff&color_border=%232681ff&promo_id=4132&campaign_id=121" charset="utf-8"></script> */}