import React from "react";
import "./App.css";

import Navigation from "./components/Navigation";
import Hero from "./components/Hero";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Offer from "./components/Offer";

function App() {
  return (
    <>
      <header>
        <Navigation />
      </header>
      <main>
        <Hero />
      </main>
      <footer>
        {/* <Footer /> */}
      </footer>
    </>
  );
}

export default App;
