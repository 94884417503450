import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  StyledBox,
  StyledBoxLeft,
  StyledButton,
  StyledInput,
  StyledTitle,
  StyledText
} from "./ContactForm.css";
import axios from "axios";
import * as zoid from "zoid/dist/zoid.frameworks";


const ContactForm = () => {

//   const load_zoid = () => {
//     zoid.create({
//       tag: "Contacts",
//       url: window.location.href
//     });
//   }
//   console.log(load_zoid)
//   document.onload = load_zoid;

//   const url = "https://api.example.com/v1/endpoint";
//   const connectionId = window.xprops.connection_data[0].id;
//   const target = "connection";
//   const body = {
//   name: "Mary",
//   emailaddress: "mary@company.com"
//   };

// let response = await window.xprops.postDataViaProxy(url, connection_id, target, body);
// console.log(response);
//   let connection = window.xprops.connection_data;

// console.log(connection);

  const fetchSubmit = async (formData) => {
    try {

      // const response = await axios({
      //   method: "POST",
      //   mode: 'no-cors',
      //   url: "https://grzegorzduzynski.activehosted.api-us1.com/api/3/contacts",
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     "Api-Token": "766852bb071ebd5c89cc23a8fb37453fb93e0b9caa45c9cab956fde593fc12297c9a0149",
      //   },
      //   data: formData
        
      const response = fetch(
        "https://grzegorzduzynski.activehosted.com/proc.php",
        {        mode: 'no-cors', 
          method: "post",
          headers: {
            "Api-Token": "766852bb071ebd5c89cc23a8fb37453fb93e0b9caa45c9cab956fde593fc12297c9a0149",
            "Content-Type": "text/plain",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log(response)
      console.log("jestem w try");

      if (response.ok) {
        console.log(response);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Błąd podczas wysyłania danych:", error);
    }
  };

  const formik = useFormik({
    initialValues: {contact: {
      email: "",
    }},

    

    onSubmit: (values) => {
      const data =  {
        contacts:{
          email:values.email
        }
      }
      console.log("onsubmit", data);
      // const formData = new FormData();
      // formData.append("form", values);
      // console.log(formData);
      fetchSubmit(data);
      // setValues({ values });
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email jest wymagany")
        .email("Wpisz prawidłowy adres"),
    
    }),
  });

  
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <StyledBoxLeft>
        
          <StyledTitle>
            Chcesz się dowiedzieć więcej?
          </StyledTitle>
          <StyledText>
            Zapisz się na listę wczesnego dostępu 
          </StyledText>
          
          <StyledBox>
            <StyledInput
                id="email"
                name="email"
                placeholder="email"
                values={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <p className="error">
                {formik.errors.email &&
                  formik.touched.email &&
                  formik.errors.email}
              </p>
          </StyledBox>
            <StyledButton type="submit">
              Chętnie sprawdzę 
            </StyledButton>

        </StyledBoxLeft>
      </form>
    </>
  );
};

export default ContactForm;
