import React, { useEffect, useLayoutEffect } from 'react';

import {
  StyledBox,
  StyledBoxLeft,
  StyledButton,
  StyledInput,
  StyledTitle,
  StyledText
} from "./ContactForm.css";

const ActiveCampaign = () => {
  
  return(
    <form method="POST" action="https://grzegorzduzynski.activehosted.com/proc.php" id="_form_3_"   >
        <input type="hidden" name="u" value="3" />
        <input type="hidden" name="f" value="3" />
        <input type="hidden" name="s" />
        <input type="hidden" name="c" value="0" />
        <input type="hidden" name="m" value="0" />
        <input type="hidden" name="act" value="sub" />
        <input type="hidden" name="v" value="2" />
        <input type="hidden" name="or" value="71e580b4-d1f4-4874-8d71-9a48192cd955" />
        <StyledBoxLeft>
        
          <StyledTitle>
            Chcesz się dowiedzieć więcej?
          </StyledTitle>
          <StyledText>
            Zapisz się na listę wczesnego dostępu 
          </StyledText>
          
          <StyledBox>
            <StyledInput
                id="email"
                name="email"
                placeholder="email"
            />
          </StyledBox>
            <StyledButton type="submit">
              Chętnie sprawdzę 
            </StyledButton>

        </StyledBoxLeft>
      </form>
    
    
    
    
    );
};

export default ActiveCampaign;
