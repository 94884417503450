import styled from "styled-components";
import { device } from "../breakpoints";

export const StyledContainer = styled.div`
  height: 50vh;
  margin: 0 auto;
  padding: 30px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  &::before {
    content: "";
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 70%;
    position: absolute;
    filter: blur(1px);

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  @media ${device.laptop} {
    gap: 20px;
  }
  @media ${device.tablet} {
    height: auto;
    flex-direction: column-reverse;
  }
`;

export const StyledBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media ${device.tablet} {
    gap: 20px;
  }
`;




export const StyledBox = styled.div`
display:flex;
flex-direction:column;
gap:14px;
justify:center;
align-items:center;
  position: relative;
    @media ${device.tabletL} {

    width: 100%;
  }
`;

export const StyledBoxContact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
export const StyledInput = styled.input`
  height: 40px;
  width: 300px;
  padding: 6px;
  @media ${device.tabletL} {
    padding: 2px;
    width: 100%;
  }
`;


export const StyledTitle = styled.h1`
  color: gray;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  letter-spacing: 3px;
  .span {
    color: ${({ theme }) => theme.firstColor};
  }
  @media ${device.tabletL} {
    color:white;
    font-size: 14px;
  }
`;


export const StyledText= styled.p`
  color: gray;
  font-size: 16px;
  // font-weight: 800;
  text-align: center;
  // letter-spacing: 3px;
  @media ${device.tabletL} {
    font-size: 12px;
    color:white;
  }
`;

export const StyledButton = styled.button`
height:30px;
width:300px;
background-color: orange;
border: orange;
color:white;
position: relative;
align-items: center;
font-weight: 600;
border-radius: 4px;
overflow: hidden;
font-size:16px;

display: flex;
justify-content: center;
flex-direction: column;
gap: 25px;
@media ${device.tabletL} {
  width: 100%;
  font-size:12px;
}
`;

