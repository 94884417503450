import styled from "styled-components";
import { device } from "../breakpoints";
import { Link } from "react-router-dom";
import img from "../2.jpg"

export const StyledContainer = styled.div`
  // min-height: 50vh;
  // padding: 100px 50px 50px 50px;
  // background-color: ${({ theme }) => theme.sixColor};
  position:relative;
  display: flex;
  flex-direction:column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  // &::before {
  //   content: "";
  //   // background-image: url("https://img.freepik.com/free-photo/transport-concept-parked-vehicles_23-2148959697.jpg?w=1380&t=st=1700819386~exp=1700819986~hmac=0b004e19bb8aabe19e3bfcd68abdab27f7c0d5890f141cd61a108a88baa3f6f5");
  //   // background-image: url(${img});
  //   background-position: center;
  //   background-attachment: fixed;
  //   background-repeat: no-repeat;
  //   background-size: cover;
    
  //   filter:brightness(1);
  //   // filter: blur(1px);
  //   // opacity: 70%;
  //   position: absolute;

  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   z-index: -1;
  // }

  @media ${device.tablet} {
    gap: 10px;
  }
`;



export const StyledBoxCards = styled.div`
  min-width:450px;
  height:300px;
  padding: 10px;
  align-items: center;
  display: flex;
  gap:20px;
  position:relative;

  @media ${device.tabletL} {
    flex-direction:column;
    gap: 10px;
  }
`;
export const StyledBox = styled.div`
  padding: 10px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media ${device.tablet} {
    gap:20px
  }
`;

export const StyledBoxIcon = styled.div`
  padding: 10px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media ${device.tablet} {
    gap:20px
  }
`;


export const StyledCard = styled.div`
  height:130px;
  width:130px;
   border-radius: 10px;
  box-shadow: 5px 5px 10px 5px rgb(154, 151, 151);
  background-color: ${({ theme }) => theme.secondColor};
  padding: 15px;
  justify-self: center;

  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  gap: 10px;

  @media ${device.tablet} {
  height:100px;
  width:100px;
  }
`;

export const StyledBoxImg = styled.div`
  height: 300px;
  width: 280px;
  @media ${device.tablet} {
    width: 180px;
    height: 180px;
  }
`;


export const StyledTitle = styled.h1`
  color: gray;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 24px;
    color:white;
  }
`;

export const StyledTitleCard = styled.h1`
  color: gray;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const StyledText = styled.p`
  color:gray; 
  font-size: 14px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 10px;
  }
`;
// export const StyledBoxCheckbox = styled.div`
//   height: 14px;
//   width: 14px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// export const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
//   height: 12px;
//   width: 12px;
//   background-color: rgb(248, 242, 242);
//   border: 1px solid rgb(214, 208, 208);
// `;

export const StyledButton = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.zeroColor};
  padding: 8px;
  border: 2px solid ${({ theme }) => theme.fourColor};
  font-weight: 600;
  border-radius: 3px;
  transition: 0.5s ease-in;

  &:hover {
    background-color: ${({ theme }) => theme.fourColor};
    /* color: ${({ theme }) => theme.fiveColor}; */
  }
  @media ${device.tablet} {
    font-size: 10px;
    padding: 4px;
  }
`;

export const StyledButtonContact = styled.button`
  width: 100%;
  background-color: transparent;
  padding: 8px;
  border: 2px solid ${({ theme }) => theme.firstColor};
  color: ${({ theme }) => theme.zeroColor};
  font-weight: 600;
  border-radius: 3px;

  &:hover {
    background-color: ${({ theme }) => theme.firstColor};
    color: ${({ theme }) => theme.zeroColor};
  }
  @media ${device.tablet} {
    font-size: 10px;
    padding: 6px;
    border: 1px solid ${({ theme }) => theme.firstColor};
    font-size: 400;
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.zeroColor};
`;
