import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPhone, faPlane } from "@fortawesome/free-solid-svg-icons";
import logo from "../PNG.png";

import {
  StyledBoxCompany,
  StyledContainer,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledTitle,
  StyledBox,
  StyledBoxContact,
  StyledTitleTel,
  StyledText,
  StyledButton,
  StyledBoxLogo,
} from "./Navigation.css";


const Navigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  const windowScreen = window;

  useEffect(() => {
    if (windowScreen.innerWidth >= 768) {
      setIsNavOpen(true);
    }
    windowScreen.addEventListener("resize", () => {
      if (windowScreen.innerWidth >= 850) {
        setIsNavOpen(true);
      } else {
        setIsNavOpen(false);
      }
    });
    return () =>
      windowScreen.removeEventListener("resize", () => {
        if (windowScreen.innerWidth >= 850) {
          setIsNavOpen(true);
        } else {
          setIsNavOpen(false);
        }
      });
  }, [windowScreen]);

  // const checkScroll = () => {
  //   let counterElement = document.getElementById("offer");
  //   let position = counterElement.getBoundingClientRect().top;

  //   if (position < window.innerHeight) {
  //     setScroll(true);
  //   } else {
  //     setScroll(false);
  //   }
  // };

  // window.addEventListener("scroll", checkScroll, { passive: true });
  return (
    <StyledContainer
      style={scroll ? { backgroundColor: "white" } : {}}
    >
      <StyledBoxCompany>
        <StyledLink smooth to="/#hero">
          <StyledBoxLogo>
            <StyledTitleTel> <span style={{color:"white"}}>Save</span> by Travel <span style={{color:"orange",textShadow:"1px 1px 0 rgb(255, 255, 255)" }}> </span></StyledTitleTel>
            <FontAwesomeIcon className="icon" icon={faPlane} />
           </StyledBoxLogo>
        </StyledLink>


      <div>
        <FontAwesomeIcon
          className="icon-menu"
          icon={faBars}
          style={{color:"white"}} 
          onClick={() => setIsNavOpen(!isNavOpen)}
        />

        {isNavOpen && (
          <nav style={{ display: "flex", alignItems: "center" }}>
            <StyledList>
              <StyledListItem>
                <StyledLink smooth to="/#offer">
                  Offer
                </StyledLink>
              </StyledListItem>
              <StyledListItem>
                <StyledLink smooth to="/#about">
                  Features
                </StyledLink>
              </StyledListItem>
              {/* <StyledListItem>
                <StyledLink smooth to="/#">
                  Pricing
                </StyledLink>
              </StyledListItem> */}
              <StyledListItem>
                <StyledLink smooth to="/#contact">
                  Support
                </StyledLink>
              </StyledListItem>
            </StyledList>
          </nav>
        )}
      </div>
      </StyledBoxCompany>
      {isNavOpen && (
      <StyledBox>
          <StyledButton>Login</StyledButton>
          <StyledButton style={{width:40}}>EN</StyledButton>
      </StyledBox>)}


    </StyledContainer>
  );
};

export default Navigation;
