import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import { device } from "../breakpoints";

export const StyledContainer = styled.div`
// max-width:1200px;
  padding: 0 30px;
  // background: rgba(0,0,0,0.3);
  // border-bottom: 1px solid ${({ theme }) => theme.secondColor};
  margin:0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.tabletL} {
    background: rgba(0,0,0,0.3);
    // padding: 10px 15px;
  }
`;

export const StyledList = styled.ul`
  display: flex;
  gap: 20px;
  @media ${device.tabletL} {

    // width:130px;
    background-color: rgba(255,255,255, 1);
    padding: 10px;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    bottom: -85px;
    right: 0;
    z-index: 10;
  }
`;

export const StyledBoxCompany = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  @media ${device.tabletL} {
    width:100%;
    justify-content: space-between;
  }
`;

export const StyledBoxLogo = styled.div`
  width: 230px;
  padding: 25px 0 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  @media ${device.tabletL} {
    width: 130px;
    padding: 10px 0 10px 0;
  }
`;
export const StyledListItem = styled.li`
  color: gray;
  transition: 0.5s ease-in;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -5px;
    background-color: orange;
    transition: 0.3s ease-in;
  }
  &:hover::after {
    width: 100%;
  }
`;

export const StyledLink = styled(Link)`
  color: white;
  @media ${device.tabletL} {
    color:gray
  }
`;

export const StyledButton = styled.button`
  width: 60px;
  background-color: transparent;
  padding: 8px;
  border: 2px solid transparent;
  color: gray;
  font-size:16px;
  font-weight: 600;
  border-radius: 2px;

  &:hover {
    scale:1.2;
    transition: 0.5s ease-in-out;
/
  }
  @media ${device.tabletL} {
    width: 50px;
    font-size: 10px;
    padding: 0;
    font-size: 400;
  }
`;


export const StyledBox = styled.div`
  padding: 10px 10px;
  display: flex;
  gap: 10px;
  @media ${device.tabletL} {
    display:none;
    padding: 0;
    gap: 0;
  }
`;


export const StyledBoxContact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const StyledTitleTel = styled.h1`
  font-size: 24px;
  /* color: black; */
  color: white; 
  @media ${device.tabletL} {
    font-size: 14px;
  }
`;

export const StyledText = styled.p`
  font-size: 14px;
  color: white;
  /* color: ${({ theme }) => theme.zeroColor}; */
  @media ${device.tabletL} {
    font-size: 9px;
  }
`;
