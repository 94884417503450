import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Offer from "./Offer"

import {
  StyledBox,
  StyledBoxTitle,
  StyledHeroContainer,
  StyledHeaderTitle,
  StyledText,
  StyledBoxAngleText,
  StyledBoxAngleIcon,
  StyledLink,
  StyledBoxLogoAndTitle,
  StyledBoxImg,
  StyledInput,
  StyledBoxInputSearch,
  StyledButtonSearch,
  StyledInputDate,
  StyledBoxInputDate,
  StyledInputPeople,
  StyledInputRooms,
  StyledBoxInputPeople,
  StyledTextSecond,
  StyledTextThird,
  StyledBoxOutside,
  StyledBoxRight
} from "./Hero.css";
import ContactForm from "./ContactForm";
import ActiveCamapaign from "./ActiveCamapign";
import MyInterviewWidget from "./widget";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right:20, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, zIndex:6,  left:20, display: "block"}}
      onClick={onClick}
    />
  );
}

const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let textColor = 'gray'; // Domyślny kolor tekstu
  if (windowWidth < 850) {
    textColor = 'white'; // Ustaw kolor szary, jeśli szerokość okna jest mniejsza niż 600
  }

  let settings = {
    dots: true,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    slidesPerRow: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    appendDots: dots => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px"
        }}
      >
        <ul style={{ margin: "20px" }}> {dots} </ul>
      </div>
    ),
  };
  return (
    <StyledHeroContainer id="hero" >

      <StyledBoxOutside>
        <StyledBox readOnly>
          <StyledBoxInputDate>
            <StyledInputDate type="date" />
            <StyledInputDate type="date" />
          </StyledBoxInputDate>
          <StyledBoxInputPeople>
            <StyledInputPeople type="text" placeholder="Ilość osób" readOnly/>
            <StyledInputRooms type="text" placeholder="Ilość pokoi" readOnly/>
          </StyledBoxInputPeople>
        <StyledBoxInputSearch >
          <StyledInput readOnly placeholder="Wpisz adres budowy..."/>
          <StyledButtonSearch readOnly>Szukaj</StyledButtonSearch>
        </StyledBoxInputSearch>
        </StyledBox>
      </StyledBoxOutside>

      <StyledBoxRight>
      <StyledBoxLogoAndTitle>
          <StyledBoxTitle>
            <StyledHeaderTitle>
              Rezerwacje dla Twojej <span style={{color:"orange"}}> Budowy</span>
            </StyledHeaderTitle>
            <StyledTextThird>
              Deleguj rezerwacje, kontroluj koszty,  <span style={{color:"orange", fontWeight:600}}>wyciągaj wnioski</span>
            </StyledTextThird>
            <StyledTextThird>
            Pracownicy będą sami rezerwować a Ty nadal będziesz miał nad tym <span style={{color:"orange", fontWeight:600}}> kontrolę </span>. Będziesz miał wgląd w <span style={{color:"orange", fontWeight:600}}>historię </span>
              rezerwacji i <span style={{color:"orange", fontWeight:600}}>kontrofert</span>. Będziesz ustalał <span style={{color:"orange", fontWeight:600}}>warunki brzegowe</span> dla pracowników i je zmieniał na podstawie historii rezerwacji. 
              {/* Ty skup się na <span style={{color:"orange", fontSize:20, fontWeight:600}}>budowaniu</span>.  */}
            </StyledTextThird>
            <StyledTextSecond >
              Dodatkowo <span style={{color:"orange", fontWeight:600}}> aplikacja podpowie</span> Twoim pracownikom hotele zamawiane w Twojej firmie w tej lokalizacji z ostatnich lat.
               {/* <span style={{color:"orange", fontWeight:600}}>to możliwe</span>  */}
            </StyledTextSecond>
          </StyledBoxTitle>
      </StyledBoxLogoAndTitle>

        {/* <MyInterviewWidget/> */}
        <Offer/>
        <ActiveCamapaign/>
        </StyledBoxRight>

    </StyledHeroContainer>

  );
};

export default Hero;
