import React, { useEffect, useState } from "react";
import {
  StyledBoxCards,
  StyledBox,
  StyledBoxImg,
  StyledCard,
  StyledContainer,
  StyledTitle,
  StyledBoxIcon,
  StyledTitleCard,
  StyledText

} from "./Offer.css";
// import { offer } from "../data"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faRoute, faShare,faWandMagicSparkles, faPhone , faBed, faChartColumn, faFilterCircleDollar} from "@fortawesome/free-solid-svg-icons";

const Offer = () => {

  return (
    <StyledContainer id="offer" >
      <StyledTitle>Jak to działa?</StyledTitle>
      <StyledBoxCards>
      <FontAwesomeIcon icon={faShare} className="arrow-szukaj"/>
      <FontAwesomeIcon icon={faShare} className="arrow-rezerwacja"/>
      <FontAwesomeIcon icon={faShare} className="arrow-weryfikacja"/>
      <FontAwesomeIcon icon={faShare} className="arrow-wnioski"/>
      <StyledCard className="szukanie">
        <StyledBoxIcon>
          <FontAwesomeIcon style={{fontSize:40, color:"rgba(0, 187, 255,0.5)"}}
                  icon={faRoute}
                />
        </StyledBoxIcon>
        <StyledTitleCard>
          WYSZUKAJ
          {/* Jedna <span  style={{color:"orange"}}>aplikacja</span> */}
        </StyledTitleCard>
        <StyledText>
        {/* hotele,         mieszkania, loty, promy i pociągi */}
        </StyledText>
      </StyledCard>
      <StyledCard className="rezerwacja">
        <StyledBoxIcon>
          <FontAwesomeIcon style={{fontSize:40, color:"rgba(0, 187, 255,0.5)"}}
                  icon={faFilterCircleDollar}
                />
        </StyledBoxIcon>
        <StyledTitleCard>
          REZERWUJ
          {/* Świadomość <span  style={{color:"orange"}}>pracowników</span> */}
        </StyledTitleCard>
        <StyledText>
        {/* Decyzje w oparciu o historię rezerwacji dla firmy i wytyczne przełozonych */}
        </StyledText>
      </StyledCard>
      <StyledCard className="werykfikacja">
        <StyledBoxIcon>
          <FontAwesomeIcon style={{fontSize:40, color:"rgba(0, 187, 255,0.5)"}}
                  icon={faChartColumn}
                />
        </StyledBoxIcon>
        <StyledTitleCard>
          WERYFIKUJ
        {/* <span  style={{color:"orange"}}>Przejrzystość</span> decyzji */}
        </StyledTitleCard>
        <StyledText>
          {/* Wyciagaj wnioski na podstawie analiz historii kontrofert  */}
        </StyledText>
      </StyledCard>
      <StyledCard className="wnioski">
        <StyledBoxIcon>
          <FontAwesomeIcon style={{fontSize:40, color:"rgba(0, 187, 255,0.5)"}}
                  icon={faWandMagicSparkles}
                />
 
        </StyledBoxIcon>
        <StyledTitleCard>
          ZMIENIAJ
        {/* <span  style={{color:"orange"}}>Przejrzystość</span> decyzji */}
        </StyledTitleCard>
        <StyledText>
          {/* Wyciagaj wnioski na podstawie analiz historii kontrofert  */}
        </StyledText>
      </StyledCard>
      
              
      </StyledBoxCards>
    </StyledContainer>
  );
};

export default Offer;
