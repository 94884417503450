import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import { device } from "../breakpoints";
import img from "../1.jpg"


export const StyledHeroContainer = styled.div`
// max-width:1200px;
height:80vh;
position:relative;
margin:100px auto;
// margin: 104px 0 70px 0;
 display:flex;
 justify-content: space-around;
 align-items:center;
// gap:80px;
padding-left: 30px;

&::before {
  content: "";
  // background-image: url("https://img.freepik.com/free-photo/transport-concept-parked-vehicles_23-2148959697.jpg?w=1380&t=st=1700819386~exp=1700819986~hmac=0b004e19bb8aabe19e3bfcd68abdab27f7c0d5890f141cd61a108a88baa3f6f5");
  background-image: url(${img});
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  
  filter:brightness(1);
  // filter: blur(1px);
  // opacity: 70%;
  position: absolute;

  top: -102px;
  bottom: -65px;
  left: 0;
  right: 50%;
  z-index: -1;
  @media ${device.tabletL} {
    right: 0;
   
  }
}

 @media ${device.tabletL} {
  flex-direction:column;
  height:auto;
  gap:20px;
  margin: 100px 0 70px 0;
  padding-left:0px;
}
`;

export const StyledInput = styled.input`
height:50px;
width:350px;
border-radius: 8px;
border: 1px solid #ddd;
padding:8px;
@media ${device.tabletL} {
  width: 100%;
  height:35px;
}
`
export const StyledInputDate = styled.input`
height:50px;
width:160px;
font-family: Sans;
border-radius: 8px;
border: 1px solid #ddd;
padding:8px;
@media ${device.tabletL} {
  width: 100%;
  height:35px;
}
`
export const StyledInputPeople = styled.input`
height:50px;
width:160px;
border-radius: 8px;
border: 1px solid #ddd;
padding:8px;
@media ${device.tabletL} {
  width: 100%;
  height:35px;
}
`
export const StyledInputRooms = styled.input`
height:50px;
width:160px;
border-radius: 8px;
border: 1px solid #ddd;
padding:8px;
@media ${device.tabletL} {
  width: 100%;
  height:35px;
}
`
export const StyledBoxInputSearch = styled.div`
 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
 
  @media ${device.tabletL} {
    width: 100%;
    gap: 15px;
  }
`;
export const StyledBoxInputDate = styled.div`

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media ${device.tabletL} {
    width: 100%;
    gap: 15px;
  }
`;

export const StyledBoxInputPeople = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media ${device.tabletL} {
    width: 100%;
    gap: 15px;
  }
`;

export const StyledButtonSearch = styled.button`
  width: 350px;
  height: 50px;
  font-size:24px;
  background-color: orange;
  color: white;

  border: orange;
  font-weight: 600;
  border-radius: 8px;
  overflow: hidden;
  transition: 0.5s ease-in;
  &:hover {
    scale: 1.05;
  }
  @media ${device.tabletL} {
    width: 100%;
    height: 35px;
    font-size: 14px;
    border: 1px solid white;
  }
`;


export const StyledBoxAngleIcon = styled.div`
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: -60px;
  transform: translateX(-50%);
`;

export const StyledBoxOutside = styled.div`
  width:580px;
  display:flex;
  justify-content: center;
  align-items:center;
  
  @media ${device.tabletL} {
    // width:100%;
  }
`;

export const StyledBox = styled.div`
  // width:80%;
  background:white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 25px;

  display: flex;
  flex-direction: column;
  gap: 25px;

  @media ${device.tabletL} {
    width:400px;


  }

`;

export const StyledBoxTitle = styled.div`
  // border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 10px;
  @media ${device.tabletL} {
    gap: 15px


  }

`;


export const StyledBoxImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 600px;
  border: 2px solid #ddd;
  overflow: hidden;

  // filter: brightness(0.6);
  animation: fadeIn 1s forwards linear;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }
`;

export const StyledBoxAngleText = styled.div`
  position: absolute;

  left: 50%;
  bottom: -30px;
  transform: translateX(-50%);
`;

export const StyledHeaderTitle = styled.h1`
  color: gray;
  font-size: 40px;
  letter-spacing: 3px;
  .span {
    color: ${({ theme }) => theme.thirdColor};
  }
  @media ${device.tabletL} {
    font-size: 20px;
    letter-spacing: 1px;
  }
`;

export const StyledText = styled.p`
  color: gray;
  /* text-align: justify; */
  @media ${device.tabletL} {
    font-size: 12px;
    font-weight:600;
  }
`;

export const StyledTextSecond = styled.p`
color: gray;
fontWeight:800;
fontSize:30;
 letterSpacing:2;
  @media ${device.tabletL} {
    font-size: 12px;
  }
`;

export const StyledTextThird = styled.p`
  color: gray;
  /* text-align: justify; */
  @media ${device.tabletL} {
    font-size: 12px;
  }
`;


export const StyledButton = styled.button`
  width: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.secondColor};

  padding: 4px;
  border: 2px solid ${({ theme }) => theme.secondColor};
  font-weight: 600;
  border-radius: 3px;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: -50px;
  transition: 0.5s ease-in;
  &:hover {
    scale: 1.15;
  }
  @media ${device.tabletL} {
    font-size: 12px;
    border: 1px solid ${({ theme }) => theme.secondColor};
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.secondColor};
  .box-button-hero {
    transition: all 500ms ease-in-out;
  }
  .title-button-hero {
    overflow: hidden;
    font-size: 20px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  .old-text,
  .new-text {
    display: block;
    overflow: hidden;
    transition: all 500ms ease-in-out;
  }
  .old-text {
    height: auto;
    opacity: 1;
    width: auto;
  }
  .new-text {
    height: 0;
    opacity: 0;
    width: 0;
  }
  .box-button-hero:hover .old-text {
    height: 0px;
    opacity: 0;
    width: 0px;
  }
  .box-button-hero:hover .new-text {
    height: auto;
    opacity: 1;
    width: auto;
  }
`;

export const StyledBoxLogoAndTitle = styled.div`
width:500px;
display: flex;
gap: 20px;
justify-content: center;
@media ${device.tabletL} {
  width:400px;
  background:white;
  flex-direction: column-reverse;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
}
`;


export const StyledBoxRight = styled.div`
width:580px;
display: flex;
flex-direction: column;
justify-content: center;
gap: 20px;
@media ${device.tabletL} {
  align-items: center;
  width:auto;
  gap: 20px;
}
`;